.border-none[data-v-05bb0890]:before {
  display: none;
}
.border-none[data-v-05bb0890]:after {
  display: none;
}
.flex-fix[data-v-05bb0890] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-05bb0890]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-05bb0890] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-05bb0890] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-05bb0890] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-05bb0890] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-05bb0890] {
  *zoom: 1;
}
.clear-fix[data-v-05bb0890]:before,
.clear-fix[data-v-05bb0890]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval-detail[data-v-05bb0890] {
  /*头像*/
  /*采购信息*/
  /*采购商品*/
  /*审批流程*/
  /*审批按钮*/
}
.approval-detail .img_more[data-v-05bb0890] {
  width: 0.7rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.04rem;
  font-size: 0.24rem;
  color: var(--main-color);
  margin-left: 0.2rem;
  background: #F3F2F5;
  position: absolute;
  right: 0.2rem;
}
.approval-detail .avatar[data-v-05bb0890] {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 0.24rem;
}
.approval-detail .status-icon[data-v-05bb0890] {
  width: 0.24rem;
  height: 0.24rem;
  position: absolute;
  right: 0.24rem;
  bottom: 0;
}
.approval-detail .name[data-v-05bb0890] {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.approval-detail .remark[data-v-05bb0890] {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
}
.approval-detail .list-confirm[data-v-05bb0890] {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.approval-detail .list-confirm .list-confirm-bk[data-v-05bb0890] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform: -webkit-translate(-50%, -50%);
          transform: -webkit-translate(-50%, -50%);
  width: 80%;
  height: 68%;
  background: #fff;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn[data-v-05bb0890] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.7rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  line-height: 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnleft[data-v-05bb0890] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  margin-top: 1px;
  background: var(--main-color);
  border-radius: 0 0 0 0.1rem;
  -webkit-border-radius: 0 0 0 0.1rem;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnright[data-v-05bb0890] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: var(--main-color);
  border-radius: 0 0 0.1rem 0;
  -webkit-border-radius: 0 0 0.1rem 0;
}
.approval-detail .list-confirm .list-confirm-bk h5[data-v-05bb0890] {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  border-bottom: 1px solid #dddddd;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box[data-v-05bb0890] {
  height: 80%;
  overflow-y: auto;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items[data-v-05bb0890] {
  padding: 0.16rem 0.23rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .list-confirm-img[data-v-05bb0890] {
  width: 1.41rem;
  height: 1.41rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box[data-v-05bb0890] {
  padding: 0.23rem 0 0.23rem 0.23rem;
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-name[data-v-05bb0890] {
  font-size: 0.28rem;
  max-width: 65%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  float: left;
  line-height: 0.36rem;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-number[data-v-05bb0890] {
  float: right;
  font-size: 0.28rem;
  padding-top: 0.23rem;
  text-align: right;
  color: #b3b3b3;
}
.approval-detail .purchase-box[data-v-05bb0890] {
  background: #fff;
  height: auto !important;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .purchase-box .top-info[data-v-05bb0890] {
  height: 1.4rem;
}
.approval-detail .purchase-box .top-info .status1[data-v-05bb0890] {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-detail .purchase-box .top-info .status2[data-v-05bb0890] {
  width: 1.38rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  font-size: 0.24rem;
  color: #999;
  border-radius: 0.28rem;
  border: 0.02rem solid #999;
}
.approval-detail .purchase-box .bottom-info[data-v-05bb0890] {
  font-size: 0.28rem;
  color: #999;
  padding: 0.3rem 0;
  height: auto !important;
}
.approval-detail .purchase-box .bottom-info div[data-v-05bb0890],
.approval-detail .purchase-box .bottom-info span[data-v-05bb0890] {
  line-height: 0.4rem;
}
.approval-detail .purchase-box .bottom-info .price[data-v-05bb0890] {
  color: var(--main-color);
}
.approval-detail .goods-box[data-v-05bb0890] {
  height: 2.04rem;
  background: #fff;
  padding: 0.24rem 0 0.22rem 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .goods-box .nav-detail[data-v-05bb0890] {
  width: 1.36rem;
  height: 0.4rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
  background: white url(../../../assets/icon_more@3x.png) no-repeat 100% 50%;
  background-size: 0.14rem 0.24rem;
  margin-right: 0.3rem;
}
.approval-detail .goods-box .goods-img img[data-v-05bb0890] {
  width: 0.96rem;
  height: 0.96rem;
  margin-right: 0.2rem;
  float: left;
}
.approval-detail .process-box[data-v-05bb0890] {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .process-box .item-list[data-v-05bb0890] {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: var(--main-color);
  margin-right: 0.24rem;
}
.approval-detail .process-box .item-list .bell[data-v-05bb0890] {
  width: 0.48rem !important;
  height: 0.48rem !important;
  color: #fff;
}
.approval-detail .process-box .location[data-v-05bb0890] {
  position: absolute;
  top: 0.4rem;
}
.approval-detail .process-box .img-list img[data-v-05bb0890] {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  margin-top: 0.2rem;
  margin-right: 0.38rem;
}
.approval-detail .process-box .line[data-v-05bb0890] {
  width: 0.04rem;
  height: 0.56rem;
  margin: 0.2rem 0.62rem 0.2rem 0.38rem;
  border: 0.02px solid #F0F0F0;
}
.approval-detail .process-box .dash-line[data-v-05bb0890] {
  width: 0.04rem;
  height: 0.56rem;
  margin: 0.2rem 0.62rem 0.2rem 0.38rem;
  border: 0.02px dashed #F0F0F0;
}
.approval-detail .process-box .refuse-reason[data-v-05bb0890] {
  background: #F3F2F5;
  font-size: 0.24rem;
  color: #FF5252;
  padding: 0.15rem;
  line-height: 1.4;
  white-space: pre-wrap;
}
.approval-detail footer[data-v-05bb0890] {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 0 0.3rem;
  -webkit-box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1rem;
  font-size: 0.28rem;
  color: #fff;
}
.approval-detail footer .reject-btn[data-v-05bb0890],
.approval-detail footer .allow-btn[data-v-05bb0890] {
  width: 3.3rem;
  height: 0.68rem;
  line-height: 0.68rem;
  border-radius: 0.45rem;
  border: 0;
}
.approval-detail footer .reject-btn[data-v-05bb0890] {
  background: #FF5252;
}
.approval-detail footer .allow-btn[data-v-05bb0890] {
  background: var(--main-color);
}
