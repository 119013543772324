.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval-detail {
  /*头像*/
  /*采购信息*/
  /*采购商品*/
  /*审批流程*/
  /*审批按钮*/
}
.approval-detail .img_more {
  width: 0.7rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.04rem;
  font-size: 0.24rem;
  color: var(--main-color);
  margin-left: 0.2rem;
  background: #F3F2F5;
  position: absolute;
  right: 0.2rem;
}
.approval-detail .avatar {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.24rem;
}
.approval-detail .status-icon {
  width: 0.24rem;
  height: 0.24rem;
  position: absolute;
  right: 0.24rem;
  bottom: 0;
}
.approval-detail .name {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.approval-detail .remark {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
}
.approval-detail .list-confirm {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.approval-detail .list-confirm .list-confirm-bk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  width: 80%;
  height: 68%;
  background: #fff;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn {
  display: flex;
  height: 0.7rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  line-height: 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnleft {
  flex: 1;
  height: 100%;
  margin-top: 1px;
  background: var(--main-color);
  border-radius: 0 0 0 0.1rem;
  -webkit-border-radius: 0 0 0 0.1rem;
}
.approval-detail .list-confirm .list-confirm-bk .list-confirm-btn .list-confirm-btnright {
  flex: 1;
  background: var(--main-color);
  border-radius: 0 0 0.1rem 0;
  -webkit-border-radius: 0 0 0.1rem 0;
}
.approval-detail .list-confirm .list-confirm-bk h5 {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  border-bottom: 1px solid #dddddd;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box {
  height: 80%;
  overflow-y: auto;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items {
  padding: 0.16rem 0.23rem;
  display: flex;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .list-confirm-img {
  width: 1.41rem;
  height: 1.41rem;
  flex: 1;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box {
  padding: 0.23rem 0 0.23rem 0.23rem;
  flex: 3;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-name {
  font-size: 0.28rem;
  max-width: 65%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  float: left;
  line-height: 0.36rem;
}
.approval-detail .list-confirm .list-confirm-bk .unavailable-items-box .unavailable-items .content-box .content-list-number {
  float: right;
  font-size: 0.28rem;
  padding-top: 0.23rem;
  text-align: right;
  color: #b3b3b3;
}
.approval-detail .purchase-box {
  background: #fff;
  height: auto !important;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .purchase-box .top-info {
  height: 1.4rem;
}
.approval-detail .purchase-box .top-info .status1 {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-detail .purchase-box .top-info .status2 {
  width: 1.38rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  font-size: 0.24rem;
  color: #999;
  border-radius: 0.28rem;
  border: 0.02rem solid #999;
}
.approval-detail .purchase-box .bottom-info {
  font-size: 0.28rem;
  color: #999;
  padding: 0.3rem 0;
  height: auto !important;
}
.approval-detail .purchase-box .bottom-info div,
.approval-detail .purchase-box .bottom-info span {
  line-height: 0.4rem;
}
.approval-detail .purchase-box .bottom-info .price {
  color: var(--main-color);
}
.approval-detail .goods-box {
  height: 2.04rem;
  background: #fff;
  padding: 0.24rem 0 0.22rem 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .goods-box .nav-detail {
  width: 1.36rem;
  height: 0.4rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
  background: white url(../../../assets/icon_more@3x.png) no-repeat 100% 50%;
  background-size: 0.14rem 0.24rem;
  margin-right: 0.3rem;
}
.approval-detail .goods-box .goods-img img {
  width: 0.96rem;
  height: 0.96rem;
  margin-right: 0.2rem;
  float: left;
}
.approval-detail .process-box {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.2rem;
}
.approval-detail .process-box .item-list {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: var(--main-color);
  margin-right: 0.24rem;
}
.approval-detail .process-box .item-list .bell {
  width: 0.48rem !important;
  height: 0.48rem !important;
  color: #fff;
}
.approval-detail .process-box .location {
  position: absolute;
  top: 0.4rem;
}
.approval-detail .process-box .img-list img {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  margin-top: 0.2rem;
  margin-right: 0.38rem;
}
.approval-detail .process-box .line {
  width: 0.04rem;
  height: 0.56rem;
  margin: 0.2rem 0.62rem 0.2rem 0.38rem;
  border: 0.02px solid #F0F0F0;
}
.approval-detail .process-box .dash-line {
  width: 0.04rem;
  height: 0.56rem;
  margin: 0.2rem 0.62rem 0.2rem 0.38rem;
  border: 0.02px dashed #F0F0F0;
}
.approval-detail .process-box .refuse-reason {
  background: #F3F2F5;
  font-size: 0.24rem;
  color: #FF5252;
  padding: 0.15rem;
  line-height: 1.4;
  white-space: pre-wrap;
}
.approval-detail footer {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 0 0.3rem;
  box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1rem;
  font-size: 0.28rem;
  color: #fff;
}
.approval-detail footer .reject-btn,
.approval-detail footer .allow-btn {
  width: 3.3rem;
  height: 0.68rem;
  line-height: 0.68rem;
  border-radius: 0.45rem;
  border: 0;
}
.approval-detail footer .reject-btn {
  background: #FF5252;
}
.approval-detail footer .allow-btn {
  background: var(--main-color);
}
